import React from 'react';
import Button from '@material-ui/core/Button';
import {navigate} from "gatsby"
import Layout from "../components/layout";

// import { Link } from "gatsby"
// import Image from "../components/image"
// import SEO from "../components/seo"

// import DrinkCard from '../components/DrinkCard'
// import Autocomplete from '../components/Autocomplete'
// import RandomCard from '../components/RandomCard'
// import {DrinkData} from '../data/DrinkData.js'
// import MenuAppBar from '../components/AppBar'
import Grow from '@material-ui/core/Grow';

import SEO from "../components/seo";

function handleClick() {
  navigate('/')
};

function SignedOut() {
  // Render
  return (<Layout>
    <SEO title = "Signed Out" />
    <div style={{
        backgroundColor: '#fcfcfc',
        height: '95vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '20px'
      }}>
      <Grow in="in">
        <div style={{
            backgroundColor: '',
            height: '100vh',
            width: '80vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <h1 style={{
              textAlign: 'center',
              fontFamily: 'Work Sans',
              fontSize: '40px'
            }}>You've successfully signed out.</h1>
          <Button onClick={handleClick} style={{
              marginTop: '4vh'
            }} size="large" variant='outlined' color="primary">Go Back Home</Button>
        </div>
      </Grow>
    </div>
  </Layout>)
}

export default SignedOut
